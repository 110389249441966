@layer components {
  .card {
    @apply bg-white dark:bg-gray-800;
    /* background: var(--base-bg-low); */
    color: var(--base-text);
    border: 1px solid var(--base-border-tertiary);
    border-radius: 8px;
    box-shadow:
      0px 1px 2px -1px rgba(0, 0, 0, 0.05),
      0px 1px 3px 0px rgba(0, 0, 0, 0.05);
    padding: 20px;
  }

  .card-cta-basic {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
  }

  .card-image {
    padding: 0px;
    overflow: hidden;
    width: 100%;

    & > :last-child {
      padding: 24px;
    }
  }

  @media (min-width: 1024px) {
    .card.card-image {
      width: 50%;
    }
  }
}
