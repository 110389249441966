@font-face {
  font-family: "Inter";
  src: url("inter.woff2") format("woff2");
  font-weight: 45 920;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Pretendard";
  src: url("pretendard.woff2") format("woff2");
  font-weight: 45 920;
  font-style: normal;
  font-display: swap;
}
