.status-green {
  @apply bg-green-100 text-green-700 px-2 py-1 rounded-full text-sm whitespace-nowrap shrink-0;
}

.status-red {
  @apply bg-red-100 text-red-700 px-2 py-1 rounded-full text-sm whitespace-nowrap shrink-0;
}

.status-orange {
  @apply bg-orange-100 text-orange-700 px-2 py-1 rounded-full text-sm whitespace-nowrap shrink-0;
}

.status-yellow {
  @apply bg-yellow-100 text-yellow-700 px-2 py-1 rounded-full text-sm whitespace-nowrap shrink-0;
}

.status-gray {
  @apply bg-gray-200 text-gray-700 px-2 py-1 rounded-full text-sm whitespace-nowrap shrink-0;
}

.status-primary {
  @apply bg-sky-100 text-sky-700 px-2 py-1 rounded-full text-sm whitespace-nowrap shrink-0;
}
