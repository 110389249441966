@import "../../../../node_modules/tom-select/dist/css/tom-select.css";

.form-group {
  @apply mb-6 relative;
}

.form-input {
  &[disabled] {
    @apply bg-gray-200;
  }
}

/* non-input elements (like the Stripe card form) can be styled to look like an input */
div.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  border-width: 1px;
  padding-top: 0.5rem;
  padding-right: 0.75rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

@layer components {
  .form-control {
    background: var(--base-bg-low);
    border: 1px solid var(--base-border-primary);
    border-radius: 6px;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
    color: var(--base-text-secondary);
    display: block;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    padding: 6px 12px;
    width: 100%;

    &::placeholder {
      color: var(--base-text-tertiary);
    }

    &:focus,
    &:focus-visible {
      box-shadow: inset 0 0 0 1px var(--border-primary-hover);
      border: 1px solid var(--border-primary-hover);
    }

    &.error {
      background: var(--bg-danger-secondary);
      box-shadow: inset 0 0 0 1px var(--border-danger);
      border: 1px solid var(--border-danger);
      color: var(--text-on-danger-secondary);

      &:focus,
      &:focus-visible {
        background: var(--base-bg-low);
      }
    }

    &[disabled],
    &[readonly] {
      background: var(--bg-disabled);
      border: 1px solid var(--border-disabled);
      color: var(--base-text-tertiary);
      cursor: not-allowed;
    }

    &[data-controller="flatpickr"] {
      color: var(--base-text-secondary);

      &:read-only {
        background: var(--base-bg-low);

        &:focus,
        &:focus-visible {
          box-shadow: inset 0 0 0 1px var(--border-primary-hover);
          border: 1px solid var(--border-primary-hover);
        }

        &:disabled {
          background: var(--bg-disabled);
          border: 1px solid var(--border-disabled);
          color: var(--base-text-tertiary);
          cursor: not-allowed;
        }

        &.error {
          background: var(--bg-danger-secondary);
          box-shadow: inset 0 0 0 1px var(--border-danger);
          border: 1px solid var(--border-danger);
          color: var(--text-on-danger-secondary);

          &:focus,
          &:focus-visible {
            background: var(--base-bg-low);
          }
        }
      }
    }
  }
}

select {
  background-color: var(--base-bg-low);
  border: 1px solid var(--base-border-primary);
  border-radius: 6px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  padding: 6px 0px;
  width: 100%;

  &[disabled],
  &[read-only] {
    background-color: var(--bg-disabled);
    border: 1px solid var(--border-disabled);
    color: var(--base-text-tertiary);
    cursor: not-allowed;
  }

  &:focus,
  &:focus-visible {
    box-shadow: inset 0 0 0 1px var(--border-primary-hover);
    border: 1px solid var(--border-primary-hover);
  }
}

select:not([multiple]) {
  padding-left: 12px;
}

select[multiple] {
  background: var(--base-bg-low);
  border: 1px solid var(--base-border-primary);
  border-radius: 6px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  padding: 6px 0px;
  width: 100%;

  &::placeholder {
    color: var(--base-text-tertiary);
  }

  &:focus,
  &:focus-visible {
    box-shadow: inset 0 0 0 1px var(--border-primary-hover);
    border: 1px solid var(--border-primary-hover);
  }

  &.error {
    background: var(--bg-danger-secondary);
    box-shadow: inset 0 0 0 1px var(--border-danger);
    border: 1px solid var(--border-danger);
    color: var(--text-on-danger-secondary);

    &:focus,
    &:focus-visible {
      background: var(--base-bg-low);
    }
  }

  &[disabled],
  &[read-only] {
    background: var(--bg-disabled);
    border: 1px solid var(--border-disabled);
    color: var(--base-text-tertiary);
    cursor: not-allowed;
  }

  option {
    color: var(--text-on-secondary);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    padding: 4px 12px;

    &[selected] {
      background: var(--bg-disabled-secondary);
    }
  }
}

label,
.label {
  color: var(--base-text-secondary);
  display: block;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 5px;
}

.form-hint {
  color: var(--base-text-secondary);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin-top: 4px;

  &.error {
    color: var(--text-danger);
    display: flex;
    align-items: center;

    &::before {
      content: "";
      display: inline-block;
      width: 16px;
      height: 16px;
      background-color: var(--icon-on-danger-secondary);
      mask-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.70013 2.25C7.27749 1.25 8.72086 1.25 9.29821 2.25L14.4944 11.25C15.0717 12.25 14.35 13.5 13.1953 13.5H2.80302C1.64832 13.5 0.926631 12.25 1.50398 11.25L6.70013 2.25ZM7.99917 4C8.41338 4 8.74917 4.33579 8.74917 4.75V7.75C8.74917 8.16421 8.41338 8.5 7.99917 8.5C7.58496 8.5 7.24917 8.16421 7.24917 7.75V4.75C7.24917 4.33579 7.58496 4 7.99917 4ZM7.99917 12C8.55146 12 8.99917 11.5523 8.99917 11C8.99917 10.4477 8.55146 10 7.99917 10C7.44689 10 6.99917 10.4477 6.99917 11C6.99917 11.5523 7.44689 12 7.99917 12Z" fill="#DC2626"/></svg>');
      mask-size: contain;
      mask-repeat: no-repeat;
      mask-position: center;
    }
  }
}

.caret {
  pointer-events: none;
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 0px;
  display: flex;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px;
  color: var(--text-on-secondary);
}

form.inline {
  .form-group,
  .file-input-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
  }

  .form-group .input {
    flex: 1 1 0%;
  }

  .form-group .label {
    width: 25%;
  }
}

/* Fix flatpickr */
.flatpickr-current-month .flatpickr-monthDropdown-months {
  display: inline-block;
}

.form-checkbox {
  @apply rounded border-gray-300 text-primary-600 focus:ring-primary-600;
  @apply dark:border-white/10 dark:checked:bg-current dark:bg-white/5 dark:focus:ring-offset-gray-900;

  &[disabled] {
    @apply bg-gray-200 dark:bg-gray-600;
  }
}

[type="checkbox"] {
  @apply form-checkbox;
}

.form-radio {
  @apply border-gray-300 text-primary-600 focus:ring-primary-600 dark:border-white/10 dark:bg-white/5 dark:focus:ring-offset-gray-900;

  &[disabled] {
    @apply bg-gray-200 dark:bg-gray-600;
  }
}

[type="radio"] {
  @apply form-radio;
}

.input-file {
  height: auto;
  z-index: 2;
  cursor: pointer;

  @apply opacity-0 inline-block pl-0 pr-0 py-3 px-3 overflow-hidden absolute border-none;

  + label {
    @apply inline-flex text-sm font-bold text-center px-4 py-0 rounded no-underline items-center justify-between cursor-pointer;

    height: 40px;
    line-height: 40px;
    transition:
      ease 0.3s background,
      ease 0.3s transform,
      ease 0.2s color;

    @apply btn;
    @apply btn-tertiary;

    &:focus {
      outline: 1px dotted #000;
      outline: -webkit-focus-ring-color auto 5px;
    }
  }
}

.switch,
.toggle {
  position: relative;

  input[type="checkbox"] {
    position: absolute;
    top: 2;
    left: 0;
    opacity: 0;

    &:checked {
      + label,
      + div label {
        &::before {
          background: var(--bg-primary);
        }

        &::after {
          transform: translateX(26px);
        }
      }
    }
  }

  label {
    position: relative;
    padding-left: 64px;

    &:hover {
      cursor: pointer;
    }

    &:focus::before,
    &:focus-visible::before {
      outline: 2px solid var(--base-border-focus);
      outline-offset: 2px;
    }

    &::before {
      background: var(--base-bg-high);
      position: absolute;
      top: 0;
      left: 0;
      height: 24px;
      width: 50px;
      content: "";
      border-radius: 24px;
      transition-property: color, background-color, border-color,
        text-decoration-color, fill, stroke;
      transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
      transition-duration: 200ms;
    }

    &::after {
      position: absolute;
      top: 2px;
      left: 2px;
      width: 20px;
      height: 20px;
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
      background: var(--base-bg-base);
      border-radius: 9999px;
      content: "";
      transition-property: transform;
      transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
      transition-duration: 200ms;
    }
  }

  p {
    position: relative;
    padding-left: 64px;
  }
}

.switch-right,
.toggle-right {
  position: relative;

  input[type="checkbox"] {
    position: absolute;
    top: 2px;
    left: 120px;
    opacity: 0;
  }

  &:has(.form-checkbox:checked) label,
  &:has([type="checkbox"]:checked) label {
    &::before {
      background: var(--bg-primary);
    }

    &::after {
      transform: translateX(26px);
    }
  }

  label {
    position: relative;
    padding-right: 64px;

    &:hover {
      cursor: pointer;
    }

    &:focus::before,
    &:focus-visible::before {
      outline: 2px solid var(--base-border-focus);
      outline-offset: 2px;
    }

    &::before {
      background: var(--base-bg-high);
      position: absolute;
      top: 0;
      right: 0;
      height: 24px;
      width: 50px;
      content: "";
      border-radius: 24px;
      transition-property: color, background-color, border-color,
        text-decoration-color, fill, stroke;
      transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
      transition-duration: 200ms;
    }

    &::after {
      position: absolute;
      top: 2px;
      right: 28px;
      width: 20px;
      height: 20px;
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
      background: var(--base-bg-base);
      border-radius: 9999px;
      content: "";
      transition-property: transform;
      transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
      transition-duration: 200ms;
    }

    &:after {
      @apply absolute top-0.5 left-0.5 rounded-full w-5 h-5 bg-white content-[""] transition-transform duration-200 ease-in;
    }
  }
}

/* Tom select styles */

.ts-wrapper {
  @apply w-full !ml-0;
}

.ts-control {
  @apply shadow-sm rounded-lg border-gray-300 bg-white py-2 px-4 text-base;

  /* Add caret https://github.com/orchidjs/tom-select/discussions/384 */
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
}

.ts-control input {
  @apply text-base;
}

.ts-dropdown {
  @apply rounded-md border border-solid border-t border-gray-300 text-base overflow-hidden;
}

.ts-dropdown [data-selectable].option:first-child {
  @apply rounded-t-lg;
}

.ts-dropdown [data-selectable].option:last-child {
  @apply rounded-b-lg;
}

.ts-dropdown .create {
  @apply text-gray-900 border-b font-medium;
}

.ts-dropdown .create:hover,
.ts-dropdown .option:hover {
  @apply bg-gray-50 text-primary-700;
}

.ts-dropdown .active {
  @apply bg-gray-50 text-gray-800;
}
