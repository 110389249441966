.tab-active {
  a {
    @apply border-primary-600 border-b-2 text-primary-600;

    &:hover,
    &:focus {
      @apply border-primary-600 text-primary-600;
    }
  }

  span {
    @apply border-primary-600 text-primary-600;

    &:hover,
    &:focus {
      @apply border-primary-600 text-primary-600;
    }
  }

  span.pill {
    @apply bg-primary-600 text-white;
  }
}
