.font-display {
  @apply text-5xl font-bold leading-tight tracking-tight;
}

p,
.p {
  @apply text-gray-600 dark:text-gray-300;
}

h1,
.h1 {
  @apply text-2xl font-medium leading-tight text-gray-900 dark:text-white;
}

h2,
.h2 {
  @apply text-lg font-medium leading-tight text-gray-900 dark:text-white;
}

h3,
.h3 {
  @apply text-[.97rem] font-medium leading-normal text-gray-900 dark:text-white;
}

h4,
.h4 {
  @apply text-sm font-medium leading-normal text-gray-900 dark:text-white;
}

h5,
.h5 {
  @apply text-sm font-medium leading-normal text-gray-600 dark:text-white;
}

h6,
.h6 {
  @apply text-sm font-medium;
}

.link {
  @apply text-[--bg-primary];

  &:hover,
  &:focus {
    @apply text-[--bg-primary];
  }
}

.breadcrumbs {
  @apply flex items-center gap-1 mb-2 text-gray-500;
}
