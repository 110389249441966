:root,
.light {
  /*Primary*/
  --bg-primary: theme("colors.primary.700");
  --bg-primary-hover: theme("colors.primary.600");
  --text-on-primary: theme("colors.white");
  --border-primary: theme("colors.primary.600");
  --border-primary-hover: theme("colors.primary.500");
  --text-primary: theme("colors.primary.700");
  --text-primary-hover: theme("colors.primary.600");

  /*Secondary*/
  --bg-secondary: theme("colors.white");
  --text-on-secondary: theme("colors.secondary.800");
  --bg-secondary-hover: theme("colors.secondary.100");
  --text-on-secondary-hover: theme("colors.secondary.700");
  --border-secondary: theme("colors.secondary.300");

  /*Danger*/
  --bg-danger: theme("colors.danger.700");
  --bg-danger-hover: theme("colors.danger.600");
  --text-on-danger: theme("colors.white");
  --border-danger: theme("colors.danger.500");
  --border-danger-focus: theme("colors.danger.500");
  --text-danger: theme("colors.danger.700");
  --bg-danger-secondary: theme("colors.danger.50");
  --text-on-danger-secondary: theme("colors.danger.700");
  --icon-on-danger-secondary: theme("colors.danger.600");

  /*Success*/
  --bg-success: theme("colors.success.800");
  --bg-success-hover: theme("colors.success.700");
  --text-on-success: theme("colors.white");
  --border-success: theme("colors.success.600");
  --border-success-focus: theme("colors.success.500");
  --text-success: theme("colors.success.800");
  --bg-success-secondary: theme("colors.success.50");
  --text-on-success-secondary: theme("colors.success.700");
  --icon-on-success-secondary: theme("colors.success.600");

  /*Info*/
  --bg-info-secondary: theme("colors.info.50");
  --text-on-info-secondary: theme("colors.info.700");
  --icon-on-info-secondary: theme("colors.info.500");

  /*Warning*/
  --bg-warning-secondary: theme("colors.warning.50");
  --text-on-warning-secondary: theme("colors.warning.700");
  --icon-on-warning-secondary: theme("colors.warning.700");

  /*Accent*/
  --bg-accent: theme("colors.accent.700");
  --text-on-accent: theme("colors.white");
  --border-accent: theme("colors.accent.500");
  --text-accent: theme("colors.accent.700");

  /*Light*/
  --bg-light: theme("colors.white");
  --bg-light-hover: theme("colors.light.50");
  --text-on-light: theme("colors.light.800");
  --text-on-light-outline: theme("colors.light.800");
  --border-light: theme("colors.light.300");

  /*Dark*/
  --bg-dark: theme("colors.dark.800");
  --bg-dark-secondary: theme("colors.dark.500");
  --bg-dark-hover: theme("colors.dark.700");
  --text-on-dark: theme("colors.white");
  --text-on-dark-outline: theme("colors.dark.800");
  --border-dark: theme("colors.dark.700");
  --border-dark-focus: theme("colors.dark.500");

  /*Disabled*/
  --bg-disabled: theme("colors.disabled.200");
  --bg-disabled-selected: theme("colors.disabled.400");
  --text-on-disabled-selected: theme("colors.disabled.200");
  --border-disabled: theme("colors.disabled.300");
  --bg-disabled-secondary: theme("colors.disabled.300");
  --icon-disabled: theme("colors.disabled.400");

  /*Base*/
  --base-text: theme("colors.gray.800");
  --base-text-secondary: theme("colors.gray.700");
  --base-text-tertiary: theme("colors.gray.500");
  --base-text-disabled: theme("colors.gray.400");
  --base-icon: theme("colors.gray.500");
  --base-bg-backdrop: theme("colors.gray.800");
  --base-bg-highest: theme("colors.gray.200");
  --base-bg-high: theme("colors.gray.100");
  --base-bg-base: theme("colors.gray.50");
  --base-bg-low: theme("colors.white");
  --base-bg-lowest: theme("colors.gray.50");
  --base-border-primary: theme("colors.gray.400");
  --base-border-secondary: theme("colors.gray.300");
  --base-border-tertiary: theme("colors.gray.200");
  --base-border-focus: theme("colors.primary.500");
  --base-bg-selected: theme("colors.primary.700");
  --base-bg-hover: theme("colors.primary.50");
  --base-bg-tooltip: theme("colors.gray.800");
  --base-text-on-tooltip: theme("colors.white");

  /* Background */
  background-color: var(--base-bg-low);
}
